<template>
  <div>
    {{ createBy.fullname }}
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    props: {
        object: {type: Object, required: true}
    },
    data(){
        return {

        }
    },
    computed: {
        ...mapGetters({
            users: 'auth/users'
        }),
        createBy(){
            return this.users.find(item => item.uid === this.object.createBy)
        }
    }

}
</script>

<style>

</style>